<template>
    <div class="first-paralax">
        <div class="layer">

        </div>
    </div>
</template>
  
<style scoped lang="scss">
.first-paralax {
    height: 450px;
    background: url("../../assets/background-images/first-paralax-jpg.jpg") no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;

    .layer {
        background-color: rgba(124, 113, 139, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: white;
    }
}
</style>