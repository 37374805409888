<template>
    <div class="third-paralax">
      <div class="layer"></div>
    </div>
  </template>
  
  <style scoped lang="scss">
  .third-paralax {
    height: 650px;
    background: url("../../assets/background-images/third-paralax.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .layer {
      background-color: rgba(124, 113, 139, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: white;
    }
  }
  </style>