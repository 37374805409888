<template>
    <div class="main-contact" id="contactme">
        <div class="contact">
            <div class="columns">
                <div class="column">
                    <h1 class="color-purple desc">Consultație Gratuită</h1>
                    <h3 class="color-purple desc">
                        Nu ezita să te înregistrezi la o consultație gratuită.
                    </h3>
                    <h3 class="color-purple desc">Completează formularul și eu revin cu un apel pentru a stabili ziua și ora
                        consultației.</h3>
                    <h3 class="color-purple desc">Date de contact:</h3>
                    <div class="flex-local color-purple contact-data">
                        <a href="tel: +37360346277" class="remove-a-decor color-purple phone">
                            <h3><i class="pi pi-phone"></i> +373 60 34 62 77</h3>
                        </a>
                        <a href="mailto: oradedictie@gmail.com" class="remove-a-decor color-purple r-s">
                            <h3><i class="pi pi-envelope"></i> oradedictie@gmail.com</h3>
                        </a>
                    </div>
                </div>
                <div class="column right-column">
                    <form ref="form" @submit.prevent="sendEmail" v-if="loading != true">
                        <div class="flex-local two-inputs">
                            <input type="text" name="user_name" placeholder="Numele tău" class="input-elem" v-model="nume"
                                required />
                            <input placeholder="Numarul de contact" type="text" name="user_phone"
                                class="input-elem second-input" v-model="email" required />
                        </div>
                        <div class="textarea">
                            <textarea name="message" id="message" cols="30" rows="10" placeholder="Mesajul tău"
                                class="input-elem textarea-elem" v-model="comment" required></textarea>
                        </div>
                        <button class="button-74" type="submit">
                            Beneficiază de o consultație
                        </button>
                    </form>
                    <div v-if="loading" class="banner-spinner">
                        <div class="spinner">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import ProgressSpinner from "primevue/progressspinner";
export default {
    components: {
        ProgressSpinner,
    },
    data() {
        return {
            nume: null,
            email: null,
            comment: null,
            loading: false,
        };
    },
    methods: {
        sendData() {
            this.nume = null;
            this.email = null;
            this.comment = null;
            if (
                this.nume != null &&
                this.nume != "" &&
                this.email != null &&
                this.email != "" &&
                this.comment != null &&
                this.comment != ""
            ) {
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Mesajul a fost transmis cu succes!",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });
            }
        },
        sendEmail() {
            this.loading = true;
            emailjs
                .sendForm(
                    "service_oradedictie",
                    "template_887xrib",
                    this.$refs.form,
                    "F3Z2352nRE2ZO1Kx4"
                )
                .then(
                    () => {
                        this.email = null
                        this.nume = null
                        this.comment = null
                        Swal.fire({
                            toast: true,
                            position: "top-end",
                            icon: "success",
                            title: "Mesajul a fost transmis cu succes!",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        });
                    },
                    (error) => {
                        console.error("FAILED...", error.text);
                        Swal.fire({
                            icon: "error",
                            title: "Eroare",
                            text: `${error.text}`,
                            confirmButtonColor: '#7C718B'
                        })
                    }
                )
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
  
<style>
.p-progress-spinner-circle {
    stroke: #7c718b !important;
}
</style>
<style scoped>
.main-contact {
    min-height: 600px;
    margin: auto;
    background-color: #d8d8d8;
}

.contact {
    max-width: 1100px;
    margin: auto;
    padding-top: 70px;
}

.button-74 {
    background-color: #7c718b;
    border: 2px solid black;
    border-radius: 30px;
    box-shadow: black 4px 4px 0 0;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 500px;
    margin-top: 20px;
}

.button-74:hover {
    background-color: #fff;
    color: black;
}

.button-74:active {
    box-shadow: black 2px 2px 0 0;
    transform: translate(2px, 2px);
}

input {
    width: 230px;
    background-color: #d8d8d8;
}

.second-input {
    margin-left: 40px;
}

.input-elem {
    border: 2px solid #7c718b;
    padding: 10px;
    font-size: 20px;
}

.textarea-elem {
    width: 500px;
    margin-top: 50px;
    background-color: #d8d8d8;
}

textarea {
    resize: none;
}

.right-column {
    margin: 30px 0 0 30px;
}

.desc {
    margin-top: 50px;
}

.r-s {
    margin-left: 50px;
}

.flex-local {
    display: flex;
}

.spinner {
    width: 100px;
    margin: 150px auto 0 auto;
}

@media (max-width: 1150px) {
    .columns {
        display: grid;
    }

    .desc {
        margin-top: 0;
        text-align: center;
    }

    .contact-data {
        max-width: 480px;
        margin: auto;
    }

    .right-column {
        margin: 0;
        max-width: 500px;
        margin: auto;
        padding: 20px 0 40px 0;
    }

    .banner-spinner {
        padding-bottom: 180px;
    }
}

@media (min-width: 768px) {
    .button-74 {
        min-width: 120px;
        padding: 0 25px;
    }
}

@media (max-width: 530px) {
    .flex-local {
        display: grid;
    }

    .contact-data {
        width: 260px;
        margin: auto;
    }

    .r-s {
        margin-left: 0;
    }

    .second-input {
        margin: 20px 0 0 0;
    }

    .phone {
        margin-left: 40px;
    }

    .two-inputs {
        width: 280px;
        margin: auto;
    }

    .textarea {
        width: 280px;
    }

    .textarea-elem {
        width: 280px;
        margin: 30px auto 0 auto;
    }

    .button-74 {
        width: 280px;
        line-height: 1.5;
    }

    .input-elem {
        width: 280px;
    }
}
</style>