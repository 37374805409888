<template>
  <div class="home">
    <HeaderComponent />
    <DespreMineComponenet />
    <FirstParalaxPhotoComponent />
    <CursuriComponent />
    <SecondParalaxPhotoComponenet />
    <DetaliiComponent />
    <BlogComponent />
    <ThirdParalaxPhotoComponent />
    <ContactComponent />
    <FooterComponent />
  </div>
</template>
<script>
import BlogComponent from "@/components/home/BlogComponent.vue"
import HeaderComponent from "@/components/home/HeaderComponent.vue";
import FooterComponent from "@/components/home/FooterComponent.vue";
import DetaliiComponent from "@/components/home/DetaliiComponent.vue";
import CursuriComponent from "@/components/home/CursuriComponent.vue";
import ContactComponent from "@/components/home/ContactComponent.vue";
import DespreMineComponenet from "@/components/home/DespreMineComponent.vue";
import FirstParalaxPhotoComponent from "@/components/home/FirstParalaxPhotoComponent.vue";
import ThirdParalaxPhotoComponent from "@/components/home/ThirdParalaxPhotoComponent.vue";
import SecondParalaxPhotoComponenet from "@/components/home/SecondParalaxPhotoComponent.vue";

export default {
  components: {
    BlogComponent,
    HeaderComponent,
    FooterComponent,
    CursuriComponent,
    DetaliiComponent,
    ContactComponent,
    DespreMineComponenet,
    FirstParalaxPhotoComponent,
    ThirdParalaxPhotoComponent,
    SecondParalaxPhotoComponenet
  },
};
</script>
