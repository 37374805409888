<template>
    <div class="parallax">
      <div class="layer">
        <div class="card">
          <Carousel
            :value="blog"
            :numVisible="3"
            :numScroll="1"
            :responsiveOptions="responsiveOptions"
            class="custom-carousel"
            :circular="true"
            :autoplayInterval="3000"
          >
            <template #item="blog">
              <div class="each-element">
                <div class="image">
                  <img
                    :src="getImageUrl(blog.data.image)"
                    class="img"
                    alt="image"
                  />
                </div>
              </div>
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Carousel from "primevue/carousel";
  
  export default {
    components: {
      Carousel,
    },
    data() {
      return {
        products: null,
        blog: [
          {
            image: "7.jpg",
          },
          {
            image: "8.jpg",
          },
          {
            image: "9.jpg",
          },
          {
            image: "10.jpg",
          },
          {
            image: "11.jpg",
          },
          {
            image: "12.jpg",
          },
          {
            image: "13.jpg",
          },
          {
            image: "14.jpg",
          },
          {
            image: "15.jpg",
          }
        ],
        responsiveOptions: [
          {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
          },
          {
            breakpoint: "720px",
            numVisible: 2,
            numScroll: 2,
          },
          {
            breakpoint: "600px",
            numVisible: 1,
            numScroll: 1,
          },
        ],
      };
    },
    methods: {
      getImageUrl(image) {
        return require("@/assets/img/" + image);
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .product-item {
    .product-item-content {
      border: 1px solid var(--surface-border);
      border-radius: 3px;
      margin: 0.3rem;
      text-align: center;
      padding: 2rem 0;
    }
  
    .product-image {
      width: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
  }
  .card {
    max-width: 1000px;
    margin: auto;
    margin-top: 10px;
  }
  .each-element {
    text-align: center;
  }
  .image {
    margin: auto;
    width: 290px;
  }
  .img {
    width: 290px;
    height: 380px;
    border-radius: 30px;
  }
  @media (max-width: 990px) {
    .image {
      margin: auto;
      width: 200px;
    }
    .img {
      width: 200px;
      height: 300px;
    }
  }
  @media (max-width: 725px) {
    .image {
      margin: auto;
      width: 220px;
    }
    .img {
      width: 220px;
      height: 300px;
    }
    .card{
      margin-top: 10px;
    }
  }
  </style>