<template>
    <div class="main-cursuri" id="cursuri">
      <div class="cursuri">
        <div class="title">
          <div class="columns">
            <img
              src="../../assets/svg/image-sonor.svg"
              class="img-sonor-1"
              alt="sonor"
            />
            <h1>CURSURI</h1>
            <img
              src="../../assets/svg/image-sonor.svg"
              class="img-sonor-2"
              alt="sonor"
            />
          </div>
        </div>
        <div class="box-elements columns">
          <div class="diffrent-box" @click="nav('dictie')">
            <h1 class="title-cursuri">Dicție și public speaking copii</h1>
            <div class="image-box">
              <img
                src="../../assets/svg/child-img.svg"
                class="child-img"
                alt="child"
              />
            </div>
          </div>
          <div class="diffrent-box" @click="nav('business')">
            <h1 class="title-cursuri">Business training</h1>
            <div class="image-box">
              <img src="../../assets/svg/group.svg" class="group-img" alt="group" />
            </div>
          </div>
          <div class="diffrent-box" @click="nav('maturi')">
            <h1 class="title-cursuri">Dicție și public speaking maturi</h1>
            <div class="image-box">
              <img src="../../assets/svg/matur.svg" class="matur-img" alt="matur" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      nav(val) {
        const position = document.getElementById(val).offsetTop;
        window.scrollTo({ top: position, behavior: "smooth" });
      },
    },
  };
  </script>
  
  <style scoped>
  .main-cursuri {
    min-height: 700px;
    background-color: #d8d8d8;
  }
  .cursuri {
    padding-top: 70px;
    max-width: 1100px;
    margin: auto;
  }
  .title {
    max-width: 520px;
    margin: auto;
  }
  .title h1 {
    text-align: center;
    color: white;
    text-shadow: 0.04419417em 0.04419417em 0em rgb(101 9 62 / 50%),
      0.08838835em 0.08838835em 0em rgb(101 9 62 / 30%);
    font-size: 50px;
  }
  .img-sonor-1 {
    transform: rotate(180deg);
    width: 70px;
    margin-right: 50px;
  }
  .img-sonor-2 {
    width: 70px;
    margin-left: 50px;
  }
  .box-elements {
    margin-top: 50px;
  }
  .diffrent-box {
    height: 320px;
    width: 320px;
    border-radius: 50%;
    margin-right: 50px;
    background-color: #7c718b;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .diffrent-box:hover {
    cursor: pointer;
  }
  .diffrent-box h1 {
    font-weight: bold;
    color: #d8d8d8;
    width: 250px;
    margin: 80px auto;
    text-align: center;
  }
  @-moz-document url-prefix() {
    .diffrent-box h1 {
      width: 260px;
    }
  }
  .image-box {
    width: 120px;
    margin: -50px auto;
  }
  .child-img {
    width: 120px;
  }
  .group-img {
    margin-top: -10px;
    width: 120px;
  }
  .matur-img {
    width: 100px;
    height: 100px;
    margin-top: -20px;
  }
  @media (max-width: 1150px) {
    .columns {
      display: grid;
      padding: 0 0 50px 0;
    }
    .diffrent-box {
      margin-bottom: 50px;
    }
    .box-elements {
      width: 320px;
      margin: auto;
    }
    .title {
      text-align: center;
      max-width: 300px;
    }
    .img-sonor-1 {
      display: none;
    }
    .img-sonor-2 {
      display: none;
    }
    .title-cursuri{
      font-size: 20px;
    }
  }
  @media (max-width: 380px) {
    .diffrent-box {
      width: 280px;
      height: 280px;
    }
    .columns {
      width: 280px;
    }
    .image-box {
      width: 100px;
      margin: -50px auto;
    }
    .child-img {
      width: 100px;
    }
    .group-img {
      width: 100px;
    }
    .matur-img {
      width: 100px;
      height: 70px;
    }
  }
  </style>