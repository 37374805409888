<template>
    <div class="main-despremine" id="despremine">
        <div class="despremine">
            <div class="columns">
                <div class="column">
                    <div class="flex">
                        <h1 class="despre-mine-title">DESPRE MINE</h1>
                        <img src="../../assets/svg/svg-microphone.svg" class="microphone" alt="microphone" />
                    </div>
                    <div class="text">
                        <p>
                            Bună, eu sunt Doina. Jurnalistă și prezentatoare TV. În cei
                            șapte ani de experiență în media am reușit să adun cele mai
                            eficiente tehnici de îmbunătățire a dicției și a discursului
                            public, care m-au ajutat să excelez profesional. Acum îmi doresc
                            să te ghidez și pe tine pentru deveni un bun orator.
                        </p>
                        <p>
                            Am adunat toată experiența mea într-un curs care te va ajuta
                            să vorbești clar, corect și melodios. După acest curs vei scăpa de
                            defectele de vorbire, vei îmbunătăți pronunția, vei putea pune
                            accente logice într-un text și vei învăța cum să-ți controlezi
                            emoțiile când vei ține un discurs în fața unui public.
                        </p>
                        <p>
                            Vin-o alături de mine pentru ați îmbunătăți abilitățile oratorice
                            și pentru deveni cea mai bună versiune a ta.
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="column-right">
                        <picture>
                            <source srcset="../../assets/main-media/despre-mine-photo.webp" type="image/webp" />
                            <source srcset="../../assets/main-media/despre-mine.jpg" type="image/jpeg" />
                            <img src="../../assets/main-media/despre-mine-photo.webp" class="despremine-photo"
                                alt="despremine" />
                        </picture>
                        <div class="flex icons-elem">
                            <div class="single-icon">
                                <a href="https://www.instagram.com/doina__chiorescu/?igshid=YmMyMTA2M2Y=" target="_blank"><i
                                        class="pi pi-instagram"></i></a>
                            </div>
                            <div class="single-icon">
                                <a href="https://www.facebook.com/doinitza.chiorescu" target="_blank"><i
                                        class="pi pi-facebook"></i></a>
                            </div>
                            <div class="single-icon">
                                <a href="https://youtube.com/channel/UCLVr1kAWz13s458gFU0hWlw" target="_blank"><i
                                        class="pi pi-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style scoped lang="scss">
.main-despremine {
    min-height: 730px;
    background-color: #d1d1d1;
}

.despremine {
    max-width: 1100px;
    margin: auto;
    padding-top: 70px;
}

.despre-mine-title {
    text-shadow: 0.04419417em 0.04419417em 0em rgb(101 9 62 / 50%),
        0.08838835em 0.08838835em 0em rgb(101 9 62 / 30%);
    font-family: "Righteous", cursive;
    color: white;
}

.text {
    margin-top: 30px;
}

.text p {
    font-size: 19px;
    font-weight: bold;
    color: #7c718b;
    line-height: 1.4;
}

.microphone {
    width: 40px;
    margin-left: 50px;
}

.column-right {
    max-width: 430px;
    margin-left: 120px;
}

.despremine-photo {
    width: 430px;
    height: 500px;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.icons-elem {
    width: 300px;
    margin: 20px auto;
}

.pi {
    font-size: 45px;
    color: #7c718b;
}

.single-icon {
    margin-left: 20px;
    border-radius: 50%;
    background-color: #fcfcfc;
    padding: 15px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.single-icon:hover {
    background-color: #7c718b !important;

    .pi {
        color: #fcfcfc;
    }
}

@media (max-width: 1150px) {
    .columns {
        display: grid;
        max-width: 900px;
        padding: 0 30px 50px 30px;
        margin: auto;
    }

    .column-right {
        margin-left: 0;
        margin: auto;
    }
}

@media (max-width: 505px) {
    .column-right {
        width: 320px;
    }

    .despremine-photo {
        width: 320px;
        height: 400px;
    }
}

@media (max-width: 400px) {
    .column-right {
        width: 250px;
    }

    .despremine-photo {
        width: 250px;
        height: 300px;
    }

    .icons-elem {
        width: 230px;
        margin: 20px auto;
    }

    .single-icon {
        padding: 10px;
    }

    .pi {
        font-size: 30px;
    }
}
</style>