<template>
  <div class="main-blog" id="blog">
    <div class="blog">
      <div class="first-line">
        <div class="title">
          <div class="border-top"></div>
          <h1>Blog</h1>
        </div>
        <div class="btn-mob">
          <div class="button">
            <p>
              Citiți toate Știrile&nbsp;
              <i class="pi pi-arrow-right" style="font-size: 15px"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="second-line">
        <CaruselComponent />
      </div>
      <div class="third-line">
        <div class="box">
          <div>
            <div class="flex">
              <div class="title-abon">
                <h2>Abonează-te la buletinul nostru de știri</h2>
              </div>
              <div>
                <div class="input">
                  <InputText
                    type="text"
                    v-model="email"
                    placeholder="exemplu@gmail.com"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="btn-abon">
            <h2>Abonează-te</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import CaruselComponent from "./SmallComponents/CaruselComponent.vue";
export default {
  components: {
    InputText,
    CaruselComponent,
  },
  data() {
    return {
      email: null,
    };
  },
};
</script>

<style scoped>
.main-blog {
  min-height: 640px;
  background-color: #d8d8d8;
  padding-top: 30px;
  padding-bottom: 40px;
}

.blog {
  max-width: 1100px;
  margin: auto;
}

.first-line {
  display: flex;
  justify-content: space-between;
  color: #7c718b;
}

.border-top {
  border-top: 2px solid #7c718b;
  width: 50px;
}

.button {
  border: 5px solid #7c718b;
  padding: 0 40px 0 40px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
}

.single-image {
  width: 240px;
  height: 340px;
  border-radius: 40px;
  cursor: pointer;
}

.box {
  display: flex;
  justify-content: space-between;
  background-color: #848fa1;
  margin-top: 10px;
  padding-left: 50px;
  border-radius: 50px;
}

.input {
  margin: 30px 0 0 100px;
}

.title-abon {
  width: 350px;
  color: #fcfcfc;
}

.btn-abon {
  border-radius: 50px;
  width: 200px;
  background-color: #fcfcfc;
  padding: 15px 0 0 20px;
  cursor: pointer;
}

@media (max-width: 1150px) {
  .first-line {
    padding: 0 50px 30px 50px;
  }

  .third-line {
    display: none;
  }

  .main-blog {
    min-height: 550px;
  }

  .card {
    padding-bottom: 50px;
  }
}

@media (max-width: 480px) {
  .first-line {
    display: grid;
  }
}
</style>
