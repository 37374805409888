<template>
    <div class="main-header" id="home">
        <div class="social-data">
            <div class="left-social">
                <p>
                    <span><i class="pi pi-envelope color-purple"></i> </span>
                    <a class="remove-a-decor color-purple" href="mailto: oradedictie@gmail.com">
                        oradedictie@gmail.com</a>
                </p>
                <p style="margin-left: 50px">
                    <span><i class="pi pi-phone color-purple"></i> </span>
                    <a class="remove-a-decor color-purple" href="tel: +37360346277">
                        +373 60 34 62 77</a>
                </p>
            </div>
            <div class="right-social">
                <a href="https://www.instagram.com/oradedictie/?igshid=YmMyMTA2M2Y%3D" target="_blank"><i
                        class="pi pi-instagram color-purple"></i></a>
                <a href="https://www.facebook.com/doinitza.chiorescu" target="_blank"><i
                        class="pi pi-facebook color-purple"></i></a>
                <a href="https://youtube.com/channel/UCLVr1kAWz13s458gFU0hWlw" target="_blank"><i
                        class="pi pi-youtube color-purple"></i></a>
            </div>
        </div>
        <div class="navbar">
            <div class="logo">
                <img src="../../assets/main-media/logos.png" class="logos" alt="logo" />
            </div>
            <div class="navigation">
                <h3><span class="nav-link" @click="nav('home')">ACASĂ</span></h3>
                <h3>
                    <span class="nav-link" @click="nav('despremine')">DESPRE MINE</span>
                </h3>
                <h3><span class="nav-link" @click="nav('cursuri')">CURSURI</span></h3>
                <h3>
                    <span class="nav-link" @click="nav('contactme')">ÎNSCRIERE</span>
                </h3>
                <h3><span class="nav-link" @click="nav('blog')">BLOG</span></h3>
            </div>
        </div>
        <div class="mobile-view">
            <div class="menu-wrap" :class="isMenuOpen == true ? 'fixed' : ''" :key="keyComp">
                <input type="checkbox" @click="isMenuOpen = !isMenuOpen" class="toggler" />
                <div class="hamburger">
                    <div></div>
                </div>
                <div class="menu">
                    <div>
                        <div class="center-nav-bar-mob">
                            <ul>
                                <li>
                                    <a @click="rerenderComp('home')" class="link-mob">ACASĂ</a>
                                </li>
                                <li>
                                    <a @click="rerenderComp('despremine')" class="link-mob">DESPRE MINE</a>
                                </li>
                                <li>
                                    <a @click="rerenderComp('cursuri')" class="link-mob">CURSURI</a>
                                </li>
                                <li>
                                    <a @click="rerenderComp('contactme')" class="link-mob">ÎNSCRIERE</a>
                                </li>
                                <li>
                                    <a @click="rerenderComp('blog')" class="link-mob">BLOG</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-and-video">
            <div class="columns">
                <div class="column column-left">
                    <h2 class="bold color-white title-and-button">
                        Înscrie-te la o consultație online gratuită de 15 minute și vei afla care sunt defectele tale de dicție.
                    </h2>
                    <div class="btn-contact">
                        <button class="button-74" @click="nav('contactme')" type="submit">
                            Înscrie-te la consultația gratuită
                        </button>
                    </div>
                </div>
                <div class="column column-right">
                    <video autoplay muted controls loop controlsList="nodownload" class="video">
                        <source src="../../assets/main-media/video.mp4#t=0,54" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <a href="tel: +37360346277" class="color-purple">
        <div class="contact-absolute-phone">
            <i class="pi pi-phone phone-absolute"></i>
        </div>
    </a>
</template>

<script>
export default {
    data() {
        return {
            keyComp: 0,
            isMenuOpen: false,
        };
    },
    methods: {
        rerenderComp(val) {
            this.keyComp += 1;
            this.isMenuOpen = false;
            this.nav(val);
        },
        nav(val) {
            const position = document.getElementById(val).offsetTop;
            window.scrollTo({ top: position, behavior: "smooth" });
        },
    },
};
</script>

<style scoped lang="scss">
.main-header {
    min-height: 730px;
    background-color: #7c718b;
}

.social-data {
    max-width: 1300px;
    margin: auto;
    background-color: #fcfcfc;
    display: flex;
    justify-content: space-between;
    padding: 0 100px 0 100px;
    font-size: 18px;
    font-weight: bold;
}

.left-social {
    width: 550px;
    display: flex;
}

.right-social i {
    font-size: 30px;
    margin-top: 13px;
}

.right-social a {
    margin-left: 20px;
}

.navbar {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}

.nav-link {
    cursor: pointer;
}

.nav-link:hover {
    text-decoration: underline;
}

.logos {
    width: 200px;
    cursor: pointer;
}

.navigation {
    display: flex;
}

.navigation h3 {
    margin: 30px 0 0 50px;
    color: #fcfcfc;
}

.title-and-video {
    max-width: 1100px;
    margin: 80px auto;
}

.title-and-button {
    max-width: 380px;
    line-height: 1.8;
    margin-top: 50px;
}

@-moz-document url-prefix() {
    .title-and-button {
        max-width: 390px;
    }
}

.style-button {
    padding: 13px;
}

.mobile-view {
    display: none;
}

.video {
    width: 650px;
    height: 380px;
}

.font-arrow {
    font-size: 28px;
}

.link-mob {
    margin-left: -40px;
    cursor: pointer;
}

.contact-absolute-phone {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: #fcfcfc;
    padding: 15px;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
}

.phone-absolute {
    font-size: 25px;
}

.button-74 {
    background-color: #7c718b;
    border: 2px solid black;
    border-radius: 30px;
    box-shadow: black 4px 4px 0 0;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 18px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 350px;
    margin-top: 20px;
}

.button-74:hover {
    background-color: #fff;
    color: black;
}

.button-74:active {
    box-shadow: black 2px 2px 0 0;
    transform: translate(2px, 2px);
}

@media (max-width: 1150px) {
    .columns {
        display: grid;
    }

    .column-left {
        text-align: center;
    }

    .column-right {
        width: 650px;
        margin: 20px auto;
    }

    .title-and-button {
        margin: 0 auto 0 auto;
    }

    .main-header {
        padding-bottom: 1px;
    }

    .navigation {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    .logo {
        margin-left: 50px;
    }

    .social-data {
        display: none;
    }

    .navbar {
        margin-top: 0;
    }

    .title-and-video {
        margin-top: 50px;
    }
}

@media (max-width: 680px) {
    .video {
        width: 450px;
        height: 250px;
    }

    .column-right {
        width: 450px;
    }
}

@media (max-width: 470px) {
    .video {
        width: 350px;
        height: 200px;
    }

    .column-right {
        width: 350px;
    }

    .title-and-button {
        padding: 0 10px 0 10px;
    }
}

@media (max-width: 400px) {
    .video {
        width: 290px;
        height: 200px;
    }

    .column-right {
        width: 290px;
    }

    .logos {
        width: 150px;
    }

    .logo {
        margin-left: 20px;
    }

    .font-arrow {
        font-size: 20px;
    }

    .button-74 {
        width: 250px;
    }
}
</style>
