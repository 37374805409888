<template>
    <div class="main-detalii">
        <div class="detalii">
            <div class="dictie" id="dictie">
                <h1 class="text-center">DICȚIE ȘI PUBLIC SPEAKING - COPII</h1>
                <div class="columns diffrence">
                    <div class="column">
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;Copilul tău vorbește prea repede, pronunță
                            cuvintele pe jumătate, are accent regional și nu este înțeles de
                            cei din jur atunci când vorbește? Emoțiile îl copleșesc de fiecare
                            dată când trebuie să vorbească în fața mai multor oameni?
                        </p>
                        <div class="dictie-2 dictie-margin">
                            <img src="../../assets/img/1.jpg" alt="dictie-1" class="dictie-2 dictie-dec" />
                        </div>
                    </div>
                    <div class="column column-right">
                        <div class="arrow">
                            <img src="../../assets/svg/arrow.svg" class="arrow-img" alt="arrow" />
                        </div>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;Atunci înscrie-l la cursul de dicție și
                            discurs public. Împreună vom lucra asupra defectele de vorbire,
                            vom îmbunătăți pronunția, vom face gimnastică respiratorie și
                            exerciții pentru corectare frazării și pentru a îmbunătăți
                            accentul logic într-un text. Dezvoltă în copilul tău calitatea de
                            lider
                        </p>
                        <div class="dictie-2 dictie-margin">
                            <img src="../../assets/img/2.jpg" alt="dictie-2" class="dictie-2 dictie-dec" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="business" id="business">
                <div class="width-business">
                    <h1 class="text-center">BUSINESS TRAINING</h1>
                    <div class="columns diffrence">
                        <div class="column">
                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;Ești antreprenor sau deții o funcție în
                                cadrul unei companii care implică comunicarea zilnică cu zeci de
                                oameni? Vrei să te perfecționezi în arta discursului și a
                                vorbirii libere? Vrei să implementezi astfel de sesiuni de
                                training pentru membrii echipei tale? Atunci hai să ne
                                cunoaștem.
                            </p>
                            <div class="dictie-2 dictie-margin">
                                <img src="../../assets/img/3.jpg" alt="business-1" class="dictie-2 dictie-dec" />
                            </div>
                        </div>
                        <div class="column column-right">
                            <div class="arrow">
                                <img src="../../assets/svg/arrow.svg" class="arrow-img" alt="arrow" />
                            </div>
                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;Împreună vom identifica problemele cu
                                care te confrunți în discuțiile pe care le porți zilnic, vom
                                lucra asupra defectelor de vorbire, vom simula anumite situații
                                care ar putea să te pună în dificultate și vom învăța cum să îți
                                controlezi emoțiile când va trebui să vorbești în fața echipei,
                                a partenerilor sau în fața camerelor de luat vederi.
                            </p>
                            <div class="dictie-2 dictie-margin">
                                <img src="../../assets/img/4.jpg" alt="business-2" class="dictie-2 dictie-dec" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="maturi" id="maturi">
                <h1 class="text-center">DICȚIE ȘI PUBLIC SPEAKING - MATURI</h1>
                <div class="columns diffrence">
                    <div class="column">
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;Dacă obișnuiești să te grăbești atunci
                            când vorbești, pronunți cuvintelor pe jumătate, ai accentul
                            regional și nu îți poți stăpâni emoțiile atunci când trebuie să
                            vorbești în fața mai multor oameni, atunci ai nevoie de acest curs
                            de dicție și discurs. Timp de trei săptămâni vom munci intensiv
                            pentru ca discursul tău să fie impecabil.
                        </p>
                        <div class="dictie-2 dictie-margin">
                            <img src="../../assets/img/5.jpg" alt="business-1" class="dictie-2 dictie-dec" />
                        </div>
                    </div>
                    <div class="column column-right">
                        <div class="arrow">
                            <img src="../../assets/svg/arrow.svg" class="arrow-img" alt="arrow" />
                        </div>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;La curs vei învăța cum se pronunță corect
                            fiecare sunet și felul în care trebuie să antrenezi mușchii care
                            răspund de pronunția sunetelor pentru a vrea o vorbire clară. La
                            fel, vei învăța reguli esențiale de frazare și de întrebuințare a
                            accentului logic într-un text.
                        </p>
                        <div class="dictie-2 dictie-margin">
                            <img src="../../assets/img/6.jpg" alt="business-2" class="dictie-2 dictie-dec" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style scoped>
.main-detalii {
    padding-bottom: 70px;
    background-color: #7c718b;
}

.detalii {
    padding-top: 50px;
}

.dictie {
    max-width: 1100px;
    margin: auto;
}

.detalii h1 {
    text-shadow: 0.04419417em 0.04419417em 0em rgb(101 9 62 / 50%),
        0.08838835em 0.08838835em 0em rgb(101 9 62 / 30%);
    color: white;
}

.detalii p {
    font-size: 19px;
    font-weight: bold;
    color: #fcfcfc;
    line-height: 1.4;
}

.diffrence {
    margin-top: 40px;
}

.dictie-margin {
    margin: auto;
}

.dictie-dec {
    margin: auto;
    border-radius: 40px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.dictie-2 {
    width: 430px;
}

.column-right {
    padding: 50px 0 0 50px;
}

.column-right p {
    padding-top: 30px;
}

.arrow-img {
    width: 150px;
    transform: rotate(20deg);
}

.business {
    margin-top: 70px;
    padding: 50px 0 70px 0;
    width: 100%;
    background-color: #d8d8d8;
}

.business p {
    color: #7C718B;
}

.width-business h1 {
    color: #7C718B;
}

.maturi {
    padding-top: 50px;
    max-width: 1100px;
    margin: auto;
}

.width-business {
    max-width: 1100px;
    margin: auto;
}

.business-1 {
    width: 350px;
    height: 400px;
}

@media (max-width: 1150px) {
    .columns {
        display: grid;
    }

    .dictie,
    .business,
    .maturi {
        padding: 50px 60px 30px 60px;
    }

    .column-right {
        padding: 50px 0 0 0;
    }

    .width-business {
        padding-bottom: 30px;
    }
}

@media (max-width: 560px) {
    .dictie-2 {
        width: 350px;
    }
}

@media (max-width: 490px) {
    .dictie-1 {
        width: 280px;
        height: 360px;
    }

    .dictie-2 {
        width: 280px;
    }

    .business-1 {
        width: 280px;
        height: 350px;
    }

    .dictie,
    .business,
    .maturi {
        padding: 50px 10px 30px 10px;
    }
}
</style>