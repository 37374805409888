<template>
    <div class="main-footer">
        <div class="footer">
            <div class="card">
                <div class="
                          flex flex-wrap
                          justify-content-center
                          card-container
                          blue-container
                          gap-6
                        ">
                    <div class="border-round w-20rem h-9rem text-white font-bold">
                        <div class="logo">
                            <img src="../../assets/main-media/logos.png" alt="logo" class="logo" @click="nav('home')" />
                        </div>
                    </div>
                    <div class="border-round w-25rem h-9rem text-white font-bold flex">
                        <div class="column">
                            <h3 class="h3" @click="nav('home')">Acasă</h3>
                            <h3 class="h3" @click="nav('cursuri')">Cursuri</h3>
                            <h3 class="h3" @click="nav('contactme')">Înscrieri</h3>
                        </div>
                        <div class="column">
                            <h3 class="h3" @click="nav('despremine')">Despre mine</h3>
                            <h3 class="h3" @click="nav('contactme')">Contact</h3>
                        </div>
                    </div>
                    <div class="
                            border-round
                            w-15rem
                            h-9rem
                            text-white
                            font-bold
                            align-items-center
                            justify-content-center
                          ">
                        <h3 class="h3-social">Grupuri sociale</h3>
                        <div class="social-media">
                            <a href="https://www.instagram.com/oradedictie/?igshid=YmMyMTA2M2Y%3D" target="_blank"><i
                                    class="pi pi-instagram color-white"></i></a>
                            <a href="https://www.facebook.com/doinitza.chiorescu" target="_blank"><i
                                    class="pi pi-facebook color-white"></i></a>
                            <a href="https://youtube.com/channel/UCLVr1kAWz13s458gFU0hWlw" target="_blank"><i
                                    class="pi pi-youtube color-white"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    methods: {
        nav(val) {
            const position = document.getElementById(val).offsetTop;
            window.scrollTo({ top: position, behavior: "smooth" });
        }
    }
};
</script>
  
<style scoped>
.main-footer {
    min-height: 300px;
    background-color: #7c718b;
}

.footer {
    max-width: 1100px;
    margin: auto;
    padding: 60px 0 40px 0;
}

.social-media {
    margin-top: 30px;
}

.social-media i {
    font-size: 30px;
}

.social-media a {
    margin-right: 20px;
}

.logo {
    cursor: pointer;
}

.h3:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1150px) {
    .footer {
        padding: 60px 50px 40px 50px;
    }

    .h3-social {
        text-align: center;
    }

    .social-media {
        margin-left: 55px;
    }
}

@media (max-width: 885px) {
    .logo {
        width: 261px;
        margin: auto;
    }
}

@media (max-width: 810px) {
    .h3 {
        text-align: center;
    }
}

@media (max-width: 380px) {
    .h3 {
        text-align: start;
        font-size: 16px;
    }

    .h3-social {
        text-align: start;
    }

    .social-media {
        margin-left: 0;
    }

    .logo {
        width: 200px;
    }
}
</style>