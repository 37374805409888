import { createApp } from "vue";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";

import App from "./App.vue";
import router from "./router";
import "./assets/styles/main.scss";
import "./assets/styles/navbar.scss";

const app = createApp(App);

app.use(router);
app.use(PrimeVue);
app.mount("#app");
